$(document).ready(function(){
    
    // Datepicker
    (function (global, factory) {
        typeof exports === 'object' && typeof module !== 'undefined' ? factory(exports) :
        typeof define === 'function' && define.amd ? define(['exports'], factory) :
        (factory((global.labels = {})));
    }(this, (function (exports) { 'use strict';

    var fp = typeof window !== "undefined" && window.flatpickr !== undefined ? window.flatpickr : {
        l10ns: {},
    };

    var texts = {
        weekdays: {
            shorthand: ["Нед", "Пон", "Вто", "Сря", "Чет", "Пет", "Съб"],
            longhand: [
                "Неделя",
                "Понеделник",
                "Вторник",
                "Сряда",
                "Четвъртък",
                "Петък",
                "Събота",
            ],
        },
        months: {
            shorthand: [
                "Яну",
                "Фев",
                "Мар",
                "Апр",
                "Май",
                "Юни",
                "Юли",
                "Авг",
                "Сеп",
                "Окт",
                "Ное",
                "Дек",
            ],
            longhand: [
                "Януари",
                "Февруари",
                "Март",
                "Април",
                "Май",
                "Юни",
                "Юли",
                "Август",
                "Септември",
                "Октомври",
                "Ноември",
                "Декември",
            ],
        }
    };

    fp.l10ns.labels = texts;
    var labels = texts;

    exports.texts = texts;
    exports['default'] = labels;

    Object.defineProperty(exports, '__bgModule', { value: true });

    })));
    
    flatpickr('.input-datepicker',{
        altFormat: 'j-m-Y',
        dateFormat: 'j-m-Y',
        'locale': 'labels'
    });

});